












import { Component } from 'vue-property-decorator';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';

@Component({
		name: 'MainListAgents',
        components: {
			AppContainerBase,
    }})
    export default class MainListAgents extends MixinMain {
        public title = this.$t("gestionAgentes.listAgent");
        public message = this.$t("gestionAgentes.agentes");
        public selectAgente = '';
        public itemsAgentes = [];

        mounted(): void {
            this.getAgents();
        }

        private getAgents() {
            const request = internet
				.newRequest()
				.get("vistaAgente/getAgentes")
			
			Util.waitForPromises([request])
            .then((response) => {
                this.itemsAgentes = response[0].data;
            })
        }

        public setAgente(event: any) {
            localStorage.setItem('user', event);
        }
    }
